import { Injectable, Signal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiGetParams, ApiResponse, Product, ProductReview, Sale } from '../../../models';
import { CrudService } from '../../crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProductV2Service extends CrudService<Product> {
  protected basePath = '/v2/stores/products';

  constructor() {
    super();
  }
}
