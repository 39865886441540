import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { isPast } from 'date-fns';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AddressOption,
  CartDto,
  CartItem,
  MakePaymentResponse,
  Order,
  OrderItem,
  OrderPaymentDto,
  OrderShippingDto,
  PaymentMethod,
  PaymentMethodsResponse,
  ShippingSettings,
  VerifyPaymentResponse
} from '../../new-models/us-models';
import { RefererService, StoreCartService } from '../../new-services/us-services';
import { CrudService } from '../index';

@Injectable({
  providedIn: 'root'
})
export class CartService extends CrudService<Order> {
  basePath = '/v1/basket/cart';

  #currentPaymentMethod = signal<PaymentMethod>(null);
  currentPaymentMethod = this.#currentPaymentMethod.asReadonly();

  #shippingSettings = signal<ShippingSettings>(null);
  #shippingSettingsLoaded = signal(false);

  #paymentMethodsResponse = signal<PaymentMethodsResponse>(null);
  #paymentMethodsResponseLoaded = signal(false);

  #makePaymentResponse = signal<MakePaymentResponse>(null);
  #makePaymentResponseLoaded = signal(false);

  #selectedDeliveryAddress = signal<AddressOption>(null);
  isIntShipping: Signal<boolean>;

  currentId: Signal<string>;
  isIntShippingSupported: Signal<boolean>;
  hasIntShippingError: Signal<boolean>;

  #refererService = inject(RefererService);
  #storeCartService = inject(StoreCartService);

  constructor() {
    super();

    this.currentId = computed(() => this.current()?.id);
    this.isIntShippingSupported = computed(() => this.current().lineItems.every(i => i.allowInternationalShipping));

    this.isIntShipping = computed(() =>  {
      const address = this.selectedDeliveryAddress();
      return address && !address.isLocalAddress;
    });

    this.hasIntShippingError = computed(() => this.isIntShipping() && !this.isIntShippingSupported());
  }

  set shippingSettings(v: ShippingSettings) {
    this.#shippingSettings.set(v);
  }

  get shippingSettings(): Signal<ShippingSettings> {
    return this.#shippingSettings.asReadonly();
  }

  set shippingSettingsLoaded(v: boolean) {
    this.#shippingSettingsLoaded.set(v);
  }

  get shippingSettingsLoaded(): Signal<boolean> {
    return this.#shippingSettingsLoaded.asReadonly();
  }

  set paymentMethodsResponse(v: PaymentMethodsResponse) {
    this.#paymentMethodsResponse.set(v);
  }

  get paymentMethodsResponse(): Signal<PaymentMethodsResponse> {
    return this.#paymentMethodsResponse.asReadonly();
  }

  set paymentMethodsResponseLoaded(v: boolean) {
    this.#paymentMethodsResponseLoaded.set(v);
  }

  get paymentMethodsResponseLoaded(): Signal<boolean> {
    return this.#paymentMethodsResponseLoaded.asReadonly();
  }

  set makePaymentResponse(v: MakePaymentResponse) {
    this.#makePaymentResponse.set(v);
  }

  get makePaymentResponse(): Signal<MakePaymentResponse> {
    return this.#makePaymentResponse.asReadonly();
  }

  set makePaymentResponseLoaded(v: boolean) {
    this.#makePaymentResponseLoaded.set(v);
  }

  get makePaymentResponseLoaded(): Signal<boolean> {
    return this.#makePaymentResponseLoaded.asReadonly();
  }

  get selectedDeliveryAddress(): Signal<AddressOption> {
    return this.#selectedDeliveryAddress.asReadonly();
  }

  set selectedDeliveryAddress(v: AddressOption) {
    this.#selectedDeliveryAddress.set(v);
  }

  setCurrentPaymentMethod(method: PaymentMethod): void {
    this.#currentPaymentMethod.set(method);
  }

  get isCurrentOrderExpired(): Signal<boolean> {
    return computed(() => {
      const order = this.current();
      return !!order?.expiresAt && isPast(new Date(order.expiresAt));
    });
  }

  getOrder(id?: string): Observable<Order> {
    id = id ?? this.currentId();

    return this.find(id);
  }

  getPaymentMethods(id?: string): Observable<PaymentMethodsResponse> {
    id = id ?? this.currentId();

    return this.apiService.get(`${this.basePath}/${id}/payment-methods`)
      .pipe(
        map(resp => resp.data),
      );
  }

  loadPaymentMethods(): void {
    this.paymentMethodsResponseLoaded = false;

    this.getPaymentMethods()
      .subscribe(resp => {
        this.paymentMethodsResponse = resp;
        this.paymentMethodsResponseLoaded = true;
      });
  }

  getShippingSettings(id?: string): Observable<ShippingSettings> {
    id = id ?? this.currentId();

    return this.apiService.get(`${this.basePath}/${id}/shipping-methods`)
      .pipe(
        map(resp => resp.data),
      );
  }

  loadShippingSettings(orderId?: string): void {
    this.shippingSettingsLoaded = false;

    this.getShippingSettings(orderId)
      .subscribe(settings => {
        this.shippingSettings = settings;
        this.shippingSettingsLoaded = true;
      });
  }

  saveCart(data: CartDto): Observable<Order> {
    const referer = this.#refererService.referer;

    if (referer) {
      data = { ...data, meta: { referer } };
    }

    return this.apiService.post(this.basePath, data)
      .pipe(
        map(resp => resp.data),
      );
  }

  saveOrder(order: Order): Observable<Order> {
    return this.saveCart(orderToCartData(order));
  }

  verifyCart(data: CartDto): Observable<Order> {
    return this.apiService.post(`${this.basePath}/verify`, data)
      .pipe(
        map(resp => resp.data),
      );
  }

  verifyAndSetCart(): Observable<Order> {
    this.currentLoaded = false;

    return this.verifyCart(this.#storeCartService.cart())
      .pipe(
        tap(order => {
          this.setCurrent(order);
          this.currentLoaded = true;
        }),
      );
  }

  purgeCheckout(): void {
    this.setCurrent(null);
    this.currentLoaded = false;
    this.selectedDeliveryAddress = null;
    this.shippingSettings = null;
  }

  verifyOrder(order: Order): Observable<Order> {
    return this.verifyCart(orderToCartData(order));
  }

  addDeliveryAddress(addressId: string, id?: string): Observable<Order> {
    id = id ?? this.currentId();

    return this.apiService.put(`${this.basePath}/${id}/delivery-address`, { addressId })
      .pipe(
        map(resp => resp.data),
        tap(order => this.setCurrent(order)),
      );
  }

  addBillingAddress(address: AddressOption, id?: string): Observable<Order> {
    id = id ?? this.currentId();

    return this.apiService.put(`${this.basePath}/${id}/billing-address`, { ...address, addressId: address.id })
      .pipe(
        map(resp => resp.data),
        tap(order => this.setCurrent(order)),
      );
  }

  addDeliveryData(data: OrderShippingDto, id?: string): Observable<Order> {
    id = id ?? this.currentId();

    return this.apiService.put(`${this.basePath}/${id}/shipping`, data)
      .pipe(
        map(resp => resp.data),
        tap(order => this.setCurrent(order)),
      );
  }

  makePayment(data: OrderPaymentDto, id?: string): Observable<MakePaymentResponse> {
    id = id ?? this.currentId();

    this.makePaymentResponseLoaded = false;

    return this.apiService.put(`${this.basePath}/${id}/make-payment`, data)
      .pipe(
        map(resp => resp.data),
        tap(resp => {
          this.makePaymentResponse = resp;
          this.makePaymentResponseLoaded = true;
        }),
      );
  }

  verifyPayment(id?: string, data: Record<string, string> = {}): Observable<VerifyPaymentResponse> {
    id = id ?? this.currentId();

    return this.apiService.post(`${this.basePath}/${id}/verify-payment`, data)
      .pipe(
        map(resp => resp.data),
      );
  }

  deleteItem(itemId: string): Observable<Order> {
    return this.deleteItems([ itemId ]);
  }

  deleteItems(itemsIds: string[], orderId?: string): Observable<Order> {
    orderId = orderId ?? this.currentId();

    return this.apiService.deleteWithBody(`${this.basePath}/${orderId}/items`, itemsIds)
      .pipe(
        map(resp => resp.data),
        tap(order => this.setCurrent(order)),
      );
  }

  renew(id?: string): Observable<Order> {
    id = id ?? this.currentId();

    return this.apiService.post(`${this.basePath}/${id}/renew`, {})
      .pipe(
        map(resp => resp.data),
      );
  }

  updateCartFromCurrent(): void {
    const order = this.current();

    this.#storeCartService.updateCart({ lineItems: order.lineItems.map(transformFromOrderItem) });
  }
}

function transformFromOrderItem(item: OrderItem): CartItem {
  return {
    productId: item.id,
    variationId: item.id,
    name: item.name,
    imageUrl: item.imageUrl,
    quantity: item.quantity,
    // todo can we avoid nested prop?
    pricing: {
      unitPrice: item.pricing.unitPrice,
    },
  };
}

function orderToCartData(order: Order): CartDto {
  return {
    voucherCode: order.voucher?.code,
    lineItems: order.lineItems.map(item => ({
      productId: item.productId,
      variationId: item.variationId,
      quantity: item.quantity
    })),
  };
}